.card-body-header-status {
    display: flex;
    flex-direction: row;
}

.card-body-header-status-dropdown {
    display: flex;
    flex-direction: column;
}

.card-body-header-status-dropdown-toggle {
    width: 150px;
}

.table-tbody-tr {
    cursor: pointer;
}

.card-body-header-title {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.distances-badge {
    margin-right: 10px;
}

.add-distance-badge {
    margin-left: 10px;
}

.coupon-badge {
    margin-right: 10px;
}

.add-coupon-badge {
    margin-left: 10px;
}