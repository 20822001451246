:local(.scrollAgenda) {
  overflow-y: 'scroll';
  width:'100%'; 
  float: 'left'; 
  height:'600px'; 
  position:'relative';
  background-color: 'red';
}

.leftSidebarNavItem {
  cursor: pointer;
}